
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
    @Component({
        name: 'PersonalAgreement'
    })
    export default class PersonalAgreement extends Vue {
        @Prop() protocolTitle!: string
        @Prop() protocolPopType!: number
        
        private stableAgressList: any = [
            [],
            [
                { 
                    text: ['本协议是用户(以下简称“您”)与勤学课程提供方(以下简称“勤学”)就勤学所提供的课程服务等相关事宜所订立的协议。本协议通过勤学移动客户端、PC客户端及相关网站等途径与客户以电子协议的方式订立。'],
                    textIndent: true, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '请您在点击同意本协议之前，审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。',
                        '当您点击确认同意本协议，即表示您已充分阅读、理解且接受本协议的全部内容，并与勤学达成一致，本协议对您及勤学均具有法律约束力。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '一、适用对象'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '本协议适用于勤学的学员和/或监护人。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '二、培训服务'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '勤学通过移动客户端、PC客户端及相关网站发布各类培训课程，在课程介绍页面展示课程名称、总课时数、开课时间、预计结课时间、上课时间、授课地点、课程价格等相关信息。您应在购买课程前仔细阅读上述信息，以确保所选课程满足自身培训需求。您在上述课程介绍页面点击‘报名“购买课程后，双方即针对所购课程的课程名称、总课时数、开课时间、预计结课时间、授课时间、授课地点、课程价格等条款达成一致，并成为本协议不可分割的一部分。在后期授课过程中如因特殊原因有所调整，勤学将提前告知您。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '三、收费标准'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '勤学的收费标准为：180元/课次，部分年级220元/课次。您对应该支付的培训费用总额无任何异议且在报名此课程之前已详细了解费用构成。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '四、班次说明'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '（一）勤学的课程分为短期班与长期班，7 次课以下为短期班，7 次课（含）以上为长期班（特殊课程除外）。',
                        '（二）短期班和长期班均无试听课，但每节课均可以旁听，旁听人员仅限学员监护人和直系亲属。每名学员每节课仅有一个旁听名额，旁听需出示听课证。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '五、退费说明',
                        '（一）不予退费的情形：',
                        '1. 各类活动和比赛的报名费不予退还。',
                        '2. 短期班、特殊优惠班级开课后不予退费；长期班剩余课次少于等于三次时不予退费。',
                        '3. 高端班型（探索班，勤学班）优惠：该学员应于当期课程结束之前到前台办理，当期课程结束之后不予退费。',
                        '4. 新学员建档费（30元）不予退费。',
                        '5. 由于学员自身原因缺课（如学生考勤未到，系统中查询无调课转班记录等情况），勤学不予补课，不予退费。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },


                {
                    text: [
                        '（二）退费方式',
                        '1.以现金形式缴纳学费的，费用将以现金方式退回。',
                        '2.使用 POS 刷卡、POS 扫码、线上自助形式缴纳学费的，退款将自动退还到原缴费账户。',
                        '（三）退费计算方式：应退金额 = 实际缴费金额 － 课程单价*系统发生课次。',
                        '课程单价：180元/课次，部分年级220元/课次；',
                        '系统发生课次：以办理退费手续当日，勤学系统内发生课次数为准。',
                        '（四）退费办理方式：',
                        '办理退费手续，必须在校区前台服务中心现场办理。同时，必须出示学员本期班听课证，且必须接受勤学前台工作人员现场电话核实办理退费人身份。勤学不接受任何形式的口头预约退费。',
                        '（五）特殊情形：'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '1. 若因您缴费途径有变（ 如微信、支付宝账号改变、银行卡注销、银行卡解绑等）而导致无法原路退回，需您自行联系微信、支付宝、银行等相关方咨询退费方式，以确保资金安全。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '2. 勤学由于特殊原因取消班次时，报名费用全额退还。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '六、调课转班'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '（一）临时调课、永久转班仅限同学年、同学期、同年级、同学科、同班型、总金额相同、总课次相同、剩余课次相同的班级之间互相办理。',
                        '（二）办理临时调课、永久转班后，需携带原听课证，在校区前台服务中心领取调课单或换取新的听课证。',
                        '（三）临时调课，必须在调入班级有可调名额的情况下才可办理，长期班每期最多办理 3 次，短期班每期最多办理 1 次。',
                        '（四）永久转班：必须在转入班级有名额的情况下才可办理。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '七、勤学的权利与义务'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '（一）勤学有权按照国家有关政策规定和约定收取培训费用。',
                        '（二）为确保培训活动顺利进行，勤学有权依照相关法律法规制定适合自身的培训管理制度，您和学员应遵照执行。',
                        '（三）勤学确保按照国家有关政策要求配备与培训项目及培训规模相适应的教学及实训设施设备，配备与培训类别、层次及规模相适应的专兼职培训队伍，按照国家规定进行培训活动。',
                        '（四）勤学将积极做好安全管理，积极落实安全防范措施，建立健全安全管理制度和应急预警处理机制，积极防范各类安全责任事故的发生。',
                        '（五）勤学将依法保护在培训过程中获悉的您及学员的个人信息，不以任何不正当的方式使用您及学员的个人隐私信息。',
                        '（六）未经您同意，勤学不会擅自将学员转交给第三方机构进行培训。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },

                {
                    text: [
                        '八、您的权利与义务'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '（一）您有权按照本协议的约定接受勤学的培训服务。',
                        '（二）您可通过旁听、课后测等适当方式了解学员的学习状况，勤学将会为您提供便利。',
                        '（三）您应当按时足额向勤学缴纳培训费用。',
                        '（四）您及学员应当自觉遵守勤学的各项培训管理制度和课堂纪律，不得妨碍其他学员的正常学习活动。您应当遵守勤学培训场所的各种安全规定，不从事危害自身或者他人人身、财产安全的不当行为。培训期间如因您或学员的原因造成勤学或他人人身、财产损害的，您应承担一切损害赔偿责任，勤学概不负责。',
                        '（五）未经勤学同意，您不得将课程转让给第三方，不得将听课证转让、出借给他人使用。您擅自将课程转让给第三方，或者将听课证转让、出借给他人使用的，勤学有权拒绝为非学员提供培训服务。',
                        '（六）如学员身体健康状况有特殊情形不再适合参与培训的，您应及时告知勤学，勤学可按照相关培训管理制度处理。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '九、知识产权'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '您及学员应当尊重勤学的知识产权，不得擅自对培训过程进行录音、录像。对于勤学拥有知识产权的培训材料或者课件，您及学员除在正常学习过程中合理使用外，不得私自复制、散发、销售，不得私自通过互联网进行分享、扩散和传播。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '如您或学员违反上述保护知识产权义务的，勤学有权解除协议、要求您和/或学员立即停止侵权行为并赔偿勤学因此产生的一切损失。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '十、违约责任'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '本协议任何一方不履行义务或者履行义务不符合约定的，应当承担继续履行、采取补救措施或者赔偿损失等违约责任。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '十一、安全制度'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '（一）遵守物业各项安全规定及教室内外安全标示。',
                        '（二）遵守秩序，做到文明上下课、课间不打闹。',
                        '（三）遵守电梯乘坐规范，排队承梯。',
                        '（四）遵守学生守则，不毁坏教室内外公共设施。',
                        '（五）禁止携带利器及易燃易爆等危险品入校。',
                        '（六）在校期间，请注意保管个人财物安全，如有丢失，勤学不承担责任。'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: false, redColor: false
                },


                {
                    text: [
                        '十二、不可抗力'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '因战争、自然灾害、传染性疾病等不可抗力导致勤学不能按照本协议约定提供培训课程的，根据不可抗力的形响，勤学可部分或者全部免除责任。在不可抗力影响下，勤学亦可变更培训方式或开课时间，例如将线下培训课程改为在线培训课程或将开课时间顺延至不可抗力情形消失。如您接受该协议变更的，双方按照变更后的培训方式或开课时间继续履行本协议。',
                        '学员受不可抗力影响无法继续学习本协议约定的培训课程，或者无法接受变更培训方式或开课时间的，您可按照本协议第五条办理退费。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '十三、争议处理'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '本协议在履行中如发生争议，双方应当协商解决，协商或者调解不成的，双方均可以向合肥市蜀山区人民法院提起诉讼。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '十四、未尽事宜'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '本协议未约定的事宜，均按照国家有关法律、法规和规章执行。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: false, redColor: false
                },
                {
                    text: [
                        '您确认：'
                    ],
                    textIndent: false, textIndent2: false, fontWeight: true, redColor: false
                },
                {
                    text: [
                        '已认真详尽阅读上述内容，并严格遵守相关规定；',
                        '同意勤学保留使用学员成绩，学习记录，课堂影像等作为宣传的权利。'
                    ],
                    textIndent: true, textIndent2: false, fontWeight: true, redColor: false
                },
            ],
            [
                { text: ['甲方（学生）：'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: ['乙方：合肥勤学培训学校'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: ['友好提示'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: [
                    '甲方应具有完全的民事行为能力。无民事行为能力的甲方学习时应经过监护人的同意。',
                    '在签署本协议之前，请甲方或代理人仔细阅读本协议各条款，如有疑问请及时咨询',
                    '甲方或代理人在签署本协议后即视为完全理解并同意接受本协议的全部条款。'
                ], textIndent: true, textIndent2: false, fontWeight: true, redColor: false },
                { text: ['为了加强勤学在校学生的安全管理，增强双方安全意识，预防和减少安全事故的发生，明确勤学学校和学生或学生监护人在学生安全学习的责、权、利，及时妥善处 理学生安全意外事故，根据《中华人民共和国教育法》、《未成年人保护法》、《预防未成年人犯罪法》和《民法通则》的有关规定，本着平等自愿的原则，特签订如下协议：'], textIndent: false, textIndent2: false, fontWeight: false, redColor: false },
                { text: ['一、学校（乙方）权利与义务'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: [
                    '（1）学校应当根据培训课程安排，保质保量的完成教学大纲要求。',
                    '（2）学校定期聘请专职老师，对学生开展安全教育，学校指派管理人员具体负责学生安全工作，加强学生安全管理。',
                    '（3）学校不得使学生在危及人身安全，健康的校舍或其他教育教学设施中活动，学校安排学生参与集会、文化娱乐、实践等集体活动，应当有利于学生的健康成长，防止发生人身安全事故。',
                    '（4）学校老师处理学生安全工作，及时了解学生中出现的各种问题和矛盾。'
                ], textIndent: true, textIndent2: false, fontWeight: false, redColor: false },
                { text: ['二、学生家长或监护人权利与义务'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },

                { text: [
                    '（1）一旦学生出现意外安全事故，学生家长或监护人有权了解事实真相，依法确定责任，依责有权享有法定权利。',
                    '（2）学生有权在学校接受安全知识教育，学生家长或监护人有权了解学生在校教育情况、安全情况等，有权向学校提出有关学生在校教育、学生安全教育管理的意见和建议',
                    '（3）学生家长或监护人应积极与学校配合，教育学生不得有旷课、夜不归宿、打架斗殴、辱骂他人、强行向他人索要财物、偷窃、故意毁坏财物，参与赌博或者变相赌博等严重违反培训学校规定，违反社会公德及违法犯罪的不良行为。',
                    '（4）学生有事、有病需要请假的，必须向学生管理人员 请假；一旦接到学校反映学生有无故旷课行为时，学生家 长或监护人应高度重视，主动配合学校及时查找，或者公 安机关等部门请求帮助。'
                ], textIndent: true, textIndent2: false, fontWeight: false, redColor: false },

                { text: ['三、安全责任'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: ['出现以下安全事故，由学校承担安全责任：'], textIndent: false, textIndent2: false, fontWeight: false, redColor: false },
                { text: [
                    '（1）学生在校期间，因学校提供的校舍和其它教育设施引发的安全事故；',
                    '（2）学生在校期间，因管理人员或任课教师擅离职守而出现的安全管理责任事故；',
                    '（3）学生参加学校组织的集会、文化娱乐、实践等集体活动期间，因学校组织责任引发的安全事故；',
                    '（4）学生在校期间，因学校安全保卫、防火防盗等严重责任事故引发的学生事故；出现以下事故，由学生家长或监护人承担安全责任：'
                ], textIndent: true, textIndent2: false, fontWeight: false, redColor: false },
                { text: [
                    '（1）学生在来校途中以及放学后归家途中或正常节假日等无课程安排期间出现的安全事故；',
                    '（2）学生违反校纪校规，不服从学校统一管理引发的安全事故；',
                    '（3）学生非因教师、学校的教学、教育管理的过错原因引起的自残、自杀等，以及受他人教唆、胁迫、引诱从事违法犯罪活动导致的安全事故；',
                    '（4）学生有特异体质或各类病情未告知学校而引发的安全事故和患病未及时治疗的伤亡事故；'
                ], textIndent: true, fontWeight: false, textIndent2: true, redColor: false },
                { text: [
                    '（5）学生在学校或校外正常学习、生活、娱乐活动等中，故意或无意而导致其他学生出现的安全事故；',
                    '（6）学生实施了违反治安管理处罚法的行为。出现以下事故，由造成事故伤害的第三方承担违约责任：'
                ], textIndent: true, textIndent2: false, fontWeight: false, redColor: false },
                { text: [
                    '（1）学校教学、生活等设施改造活动中，因施工单位原因造成的安全事故。'
                ], textIndent: true, fontWeight: false, textIndent2: true, redColor: false },

                { text: ['四、 免责条款'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: [
                    '因台风、龙卷风、暴雨、洪水、地震等不可抗力引发的安全事故，由政府的统一领导下具体解决。'
                ], textIndent: true, textIndent2: false, fontWeight: false, redColor: false },

                { text: ['五、其他'], textIndent: false, textIndent2: false, fontWeight: true, redColor: false },
                { text: [
                    '（1）本协议未尽事宜甲乙双方另行商议解决，协商不成的则按照国家有关规定执行。',
                    '（2）协议执行过程中如有争议经协商不能解决的，则交由乙方所在地人民法院审理。',
                    '（3）本协议书一式两份，甲乙双方各执一份，自双方签字或盖章之日起生效。',
                    '（4）合同有效期为：自 年 月 日至 年 月 日止。'
                ], textIndent: true, textIndent2: false, fontWeight: false, redColor: false },
            ],
            [
                { text: [
                    '1. 临时调课仅限于同年份. 同学期. 同年级. 同学科. 同班型. 总金额相同. 总课次相同. 剩余课次相同的班级之间互相办理。',
                    '2. 临时调课必须在调入目标班级有可调名额的情况下才可以办理，长期班（课程总课时大于等于7次课）每期课程最多可以办理3次， 短期班（课程总课时小于7次课）每期最多可以办理1次；',
                    '3. 临时调课需在课前调，课程时间一旦开始则无法进行调课。',
                    '4. 调课后需至校区前台领取调课单，交给调课后的任课老师。 ',
                    '5. 如有疑问可到校区前台咨询或致电呼叫中心：0551 - 69106630'
                ], textIndent: false, textIndent2: false, fontWeight: false, redColor: false },
            ],
            [
                { text: [
                    '1. 永久转班仅限于同年份、同学期、同年级、同学科、同级别班型、课程总金额相同、总课次相同、剩余课次相同的班级之间相互办理；',
                    '2. 永久转班必须在转入目标班级有名额的情况下才可办理。',
                    '3. 永久转班办理成功后即时生效，孩子退出原班级，原班级名额空出不保留。',
                    '4. 如果需要转回原班级，需要重新办理永久转班业务。',
                    '5. 请于下一次课程开课前到校区前台更换新的听课证。',
                    '6. 如有疑问可到校区前台咨询或致电呼叫中心：0551 - 69106630'
                ], textIndent: false, textIndent2: false, fontWeight: false, redColor: false },
            ]
        ]
        private agreementsList: Array<any> = []

        @Emit()
        private closeEvent (): void {}

        @Watch('protocolPopType', { immediate: true, deep: true })
        protocolPopTypeWatch(newVal: number): void {
            if(newVal) {
                this.agreementsList = this.stableAgressList[this.protocolPopType]
            }
        }
    }
